import styled from 'styled-components'
import theme, { media } from '../../helpers/theme'

const FAQ = styled.div`
  padding: ${theme.space[5]}px 0;
`

FAQ.container = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: space-between;
  ${media(768)} {
    width: 750px;
  }

  ${media(992)} {
    width: 970px;
  }

  ${media(1200)} {
    width: 1170px;
  }
`

FAQ.questionContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  padding: 0 ${theme.space[8]}px;

  ${media('md')} {
    min-width: 350px;
    flex-basis: 38%;
  }
`

FAQ.title = styled.div`
  font-size: ${theme.font['base']};
  color: ${theme.colors.font.lightgray};
  padding: ${theme.space[6]}px 0;
`

FAQ.subtitle = styled.div`
  font-size: ${theme.font['sm']};
  color: ${theme.colors.font.fadedgray};
  line-height: 22px;
`

FAQ.disclaimer = styled.div`
  margin-top: ${theme.space[20]}px;
  margin-bottom: ${theme.space[4]}px;
  color: ${theme.colors.font.fadedgray};
  font-style: italic;
  font-size: ${theme.font['sm']};
  padding: 0 ${theme.space[6]}px;
`

export default FAQ
