import React from 'react'
import {
  FREQUENT_QUESTIONS,
  DISCLAIMER,
  DISCLAIMER2,
} from './frequent-questions.constants'
import FAQ from './frequent-questions.styles'
import Typography from '@eig-builder/core-ui/Typography'

const FrequentQuestions = () => {
  return (
    <FAQ>
      <FAQ.container>
        {FREQUENT_QUESTIONS.map((question) => (
          <>
            <FAQ.questionContainer>
              <FAQ.title>{question.title}</FAQ.title>
              <FAQ.subtitle>{question.subtitle}</FAQ.subtitle>
            </FAQ.questionContainer>
          </>
        ))}
        <FAQ.disclaimer>
          <Typography gutterBottom>
            {DISCLAIMER}{' '}
            <a
              href={process.env.GATSBY_EU_VAT}
              targe='_blank'
              rel='noopener norefferer'
            >
              Learn more
            </a>
          </Typography>
          <Typography>{DISCLAIMER2}</Typography>
        </FAQ.disclaimer>
      </FAQ.container>
    </FAQ>
  )
}

export default FrequentQuestions
