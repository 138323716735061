import React from 'react'
import FrequentQuestions from '../components/frequent-questions'
import Layout from '../components/layout'
import CTA from '../components/cta'
import { PRICING_TEXT } from '../helpers/constants'
import SEO from '../components/seo'
import PricingCards from '../components/pricing-cards'

const { CTA_TITLE, CTA_BUTTON } = PRICING_TEXT

const Pricing = () => {
  return (
    <Layout>
      <SEO title='Pricing' pageType='home' flowType='none' />
      <PricingCards />
      <CTA title={CTA_TITLE} button={CTA_BUTTON} />
      <FrequentQuestions />
    </Layout>
  )
}

export default Pricing
