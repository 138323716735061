import { getCurrencySymbol } from 'helpers/currency-helper'

export const FREQUENT_QUESTIONS = [
  {
    id: 1,
    title: 'HOW DO I GET STARTED?',
    subtitle:
      "Creating a website has never been so easy. Select a theme to get started. Choose your domain name, then click publish. It's that simple!",
  },
  {
    id: 2,
    title: 'HOW LONG ARE YOUR CONTRACTS?',
    subtitle:
      'To make it easy, we offer monthly, annual and two-year plans. It’s simple to start or stop your service at any time. Your plan will renew automatically to ensure no downtime to your website.',
  },
  {
    id: 3,
    title: 'IS THERE A DISCOUNT FOR ANNUAL PLANS?',
    subtitle:
      'Of course! We offer discounts for up front long term plans. You will also receive a free custom domain* of your choice with our one and two year plans.',
  },
  {
    id: 4,
    title: 'CAN I SWITCH PLANS?',
    subtitle:
      'Yes, you can switch plans at any time. When upgrading or downgrading plans you will receive a pro-rated charge or a partial refund.',
  },
]

export const DISCLAIMER =
  `*For customers in regions in which VAT (Value Added Tax) or GST (Goods and Services Tax) is applicable, taxes are not included in our advertised price and will be charged separately and itemized on invoices and billing information.`

export const DISCLAIMER2 =
  `**Qualified plans include a free domain registration for one year. If you wish to cancel, there is a non-refundable ${getCurrencySymbol().domainPrice} domain fee.`
